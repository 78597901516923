.navbar {
  position: -webkit-sticky;
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0px;
  width: 100%;
  height: 4rem;
  background-color: #ffffff;
  z-index: 1000;
  box-shadow: rgba(170, 172, 177, 0.3) 0px 1px 2px 1px,
    rgba(162, 162, 168, 0.15) 0px 1px 3px 1px;
}

.navlink {
  height: 4rem !important;
  margin-right: 2% !important;
  padding: 0 50px !important;
  border-radius: 0 !important;
  border-bottom: 5px solid #fff !important;
}

.activeNavLink {
  color: #f50057 !important;
  border-bottom: 5px solid #f50057 !important;
}
.paper {
  min-width: 250px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px !important;
}

.profileBtns {
  display: flex !important;
  justify-content: flex-start !important;
  text-transform: none !important;
}
