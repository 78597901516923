.datePickerContainer {
  box-shadow: rgba(62, 62, 119, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px !important;
}

.rdrInputRangeInput {
  width: 50px !important;
}
.rdrDayInPreview,
.rdrDayStartPreview,
.rdrDayEndPreview {
  margin: -1px !important;
}

.rdrDayStartOfWeek .rdrInRange,
.rdrDayStartOfWeek .rdrEndEdge,
.rdrDayStartOfWeek .rdrDayInPreview,
.rdrDayStartOfWeek .rdrDayEndPreview,
.rdrDayStartPreview,
.rdrStartEdge {
  border-top-left-radius: 0.3rem !important;
  border-bottom-left-radius: 0.3rem !important;
  border-top-width: 1px !important;
  border-bottom-width: 1px !important;
  border-left-width: 1px !important;
}

.rdrDayStartOfMonth .rdrInRange,
.rdrDayStartOfMonth .rdrDayInPreview {
  border-top-left-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
}

.rdrDayEndOfWeek .rdrInRange,
.rdrDayEndOfWeek .rdrStartEdge,
.rdrDayEndOfWeek .rdrDayInPreview,
.rdrDayEndOfWeek .rdrDayStartPreview,
.rdrDayEndPreview,
.rdrEndEdge {
  border-top-right-radius: 0.3rem !important;
  border-bottom-right-radius: 0.3rem !important;
  border-top-width: 1px !important;
  border-bottom-width: 1px !important;
  border-right-width: 1px !important;
}

.rdrDayEndOfMonth .rdrInRange,
.rdrDayEndOfMonth .rdrDayInPreview {
  border-top-right-radius: 1rem !important;
  border-bottom-right-radius: 1rem !important;
}

.rdrDayInPreview {
  border-top-width: 1px !important;
  border-bottom-width: 1px !important;
}
