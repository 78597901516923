$base-background-color: #ffffff !default;
$base-font-color: #999 !default;
$base-font-family: 'aktiv-grotesk-std', Helvetica Neue, Arial, sans-serif !default;
$base-font-size: 18px !default;
$base-line-height: 24px !default;


//=====================================
//  Grid
//-------------------------------------
$grid-max-width: 810px !default;
